import { FC } from 'react';

const RadioCheckedIcon: FC = (props) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="15" cy="15" r="14.5" fill="white" stroke="#B2B2B2"/>
      <circle cx="15" cy="15" r="8" fill="#505050"/>
    </svg>
  );
};

export default RadioCheckedIcon;
