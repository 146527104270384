import { FC } from 'react';

const CheckBoxIcon: FC = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="29" height="29" rx="4.5" fill="white" stroke="#B2B2B2"/>
    </svg>
  );
};

export default CheckBoxIcon;
