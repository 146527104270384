export default function Icon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 7C7.77614 7 8 7.17909 8 7.4V10.6C8 10.8209 7.77614 11 7.5 11C7.22386 11 7 10.8209 7 10.6V7.4C7 7.17909 7.22386 7 7.5 7Z"
        fill="#191919"
      />
      <path
        d="M7.49505 4C7.22164 4 7 4.22386 7 4.5C7 4.77614 7.22164 5 7.49505 5H7.50495C7.77836 5 8 4.77614 8 4.5C8 4.22386 7.77836 4 7.50495 4H7.49505Z"
        fill="#191919"
      />
      <path
        d="M0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5ZM7.5 0.714286C3.75235 0.714286 0.714286 3.75235 0.714286 7.5C0.714286 11.2476 3.75235 14.2857 7.5 14.2857C11.2476 14.2857 14.2857 11.2476 14.2857 7.5C14.2857 3.75235 11.2476 0.714286 7.5 0.714286Z"
        fill="#191919"
      />
    </svg>
  );
}
