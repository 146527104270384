import { FC, SVGProps } from 'react';

const JSBIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="39" height="30" viewBox="0 0 39 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M38.5716 23.9524C38.5716 27.2857 35.8839 30 32.5831 30H0V6.04762C0 2.71429 2.68776 0 5.98851 0H38.5716V23.9524Z" fill="white"/>
    <path d="M27.9622 17.8095H30.4378C30.5085 17.8095 30.6736 17.7857 30.7443 17.7857C31.2158 17.6905 31.6166 17.2619 31.6166 16.6667C31.6166 16.0952 31.2158 15.6667 30.7443 15.5476C30.6736 15.5238 30.5321 15.5238 30.4378 15.5238H27.9622V17.8095Z" fill="url(#paint0_linear_2_153)"/>
    <path d="M30.1547 2.0238C27.7971 2.0238 25.8638 3.95238 25.8638 6.35714V10.8571H31.923C32.0645 10.8571 32.2295 10.8571 32.3474 10.8809C33.7148 10.9524 34.7286 11.6667 34.7286 12.9048C34.7286 13.8809 34.0449 14.7143 32.7718 14.8809V14.9286C34.1628 15.0238 35.2237 15.8095 35.2237 17.0238C35.2237 18.3333 34.0449 19.1905 32.4888 19.1905H25.8402V28H32.1352C34.4929 28 36.4262 26.0714 36.4262 23.6667V2.0238H30.1547Z" fill="url(#paint1_linear_2_153)"/>
    <path d="M31.3101 13.1905C31.3101 12.6191 30.9093 12.2381 30.4378 12.1667C30.3906 12.1667 30.2727 12.1429 30.202 12.1429H27.9622V14.2381H30.202C30.2727 14.2381 30.4142 14.2381 30.4378 14.2143C30.9093 14.1429 31.3101 13.7619 31.3101 13.1905Z" fill="url(#paint2_linear_2_153)"/>
    <path d="M6.43638 2.0238C4.0787 2.0238 2.1454 3.95238 2.1454 6.35714V17.0476C3.34782 17.6429 4.59739 18.0238 5.84696 18.0238C7.3323 18.0238 8.13391 17.119 8.13391 15.8809V10.8333H11.8119V15.8571C11.8119 17.8095 10.6095 19.4048 6.53069 19.4048C4.05512 19.4048 2.12183 18.8571 2.12183 18.8571V27.9762H8.41683C10.7745 27.9762 12.7078 26.0476 12.7078 23.6429V2.0238H6.43638Z" fill="url(#paint3_linear_2_153)"/>
    <path d="M18.2954 2.0238C15.9377 2.0238 14.0044 3.95238 14.0044 6.35714V12.0238C15.089 11.0952 16.9751 10.5 20.0165 10.6429C21.6433 10.7143 23.388 11.1667 23.388 11.1667V13C22.5156 12.5476 21.4783 12.1428 20.1344 12.0476C17.8239 11.8809 16.4328 13.0238 16.4328 15.0238C16.4328 17.0476 17.8239 18.1905 20.1344 18C21.4783 17.9048 22.5156 17.4762 23.388 17.0476V18.8809C23.388 18.8809 21.6669 19.3333 20.0165 19.4048C16.9751 19.5476 15.089 18.9524 14.0044 18.0238V28.0238H20.2994C22.6571 28.0238 24.5904 26.0952 24.5904 23.6905V2.0238H18.2954Z" fill="url(#paint4_linear_2_153)"/>
    <defs>
      <linearGradient id="paint0_linear_2_153" x1="25.8514" y1="16.6746" x2="36.4475" y2="16.6746" gradientUnits="userSpaceOnUse">
        <stop stop-color="#007940"/>
        <stop offset="0.2285" stop-color="#00873F"/>
        <stop offset="0.7433" stop-color="#40A737"/>
        <stop offset="1" stop-color="#5CB531"/>
      </linearGradient>
      <linearGradient id="paint1_linear_2_153" x1="25.8516" y1="15.0198" x2="36.4376" y2="15.0198" gradientUnits="userSpaceOnUse">
        <stop stop-color="#007940"/>
        <stop offset="0.2285" stop-color="#00873F"/>
        <stop offset="0.7433" stop-color="#40A737"/>
        <stop offset="1" stop-color="#5CB531"/>
      </linearGradient>
      <linearGradient id="paint2_linear_2_153" x1="25.8514" y1="13.1984" x2="36.4465" y2="13.1984" gradientUnits="userSpaceOnUse">
        <stop stop-color="#007940"/>
        <stop offset="0.2285" stop-color="#00873F"/>
        <stop offset="0.7433" stop-color="#40A737"/>
        <stop offset="1" stop-color="#5CB531"/>
      </linearGradient>
      <linearGradient id="paint3_linear_2_153" x1="2.13218" y1="15.0079" x2="12.8913" y2="15.0079" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1F286F"/>
        <stop offset="0.4751" stop-color="#004E94"/>
        <stop offset="0.8261" stop-color="#0066B1"/>
        <stop offset="1" stop-color="#006FBC"/>
      </linearGradient>
      <linearGradient id="paint4_linear_2_153" x1="13.9353" y1="15.0317" x2="24.3845" y2="15.0317" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6C2C2F"/>
        <stop offset="0.1735" stop-color="#882730"/>
        <stop offset="0.5731" stop-color="#BE1833"/>
        <stop offset="0.8585" stop-color="#DC0436"/>
        <stop offset="1" stop-color="#E60039"/>
      </linearGradient>
    </defs>
</svg>
);

export default JSBIcon;
