import { FC, SVGProps } from 'react';

import { CreditCardBrandType } from 'types/types';
import {
  CARD_BRAND_VISA,
  CARD_BRAND_AMEX,
  CARD_BRAND_MASTERCARD,
  CARD_BRAND_DISCOVER,
  CARD_BRAND_DINERS,
  CARD_BRAND_JCB,
  CARD_BRAND_CHINA_UNION_PAY,
} from 'data/constants';
import MasterCardIcon from 'components/icons/CardBrandIcons/MasterCard';
import AmericanExpressIcon from 'components/icons/CardBrandIcons/AmericanExpressIcon';
import VisaIcon from 'components/icons/CardBrandIcons/VisaIcon';
import DiscoverIcon from 'components/icons/CardBrandIcons/DiscoverIcon';
import DinersIcon from 'components/icons/CardBrandIcons/DinersIcon';
import JSBIcon from './CardBrandIcons/JSBIcon';
import CUPIcon from './CardBrandIcons/CUPIcon';
import DefaultCardIcon from './CardBrandIcons/DefaultCardIcon';

interface ICreditCardBrandIcon extends SVGProps<SVGSVGElement> {
  creditCardBrand: CreditCardBrandType,
};

const CreditCardBrandIcon: FC<ICreditCardBrandIcon> = ({
  creditCardBrand,
  ...props
}) => {
  switch (creditCardBrand) {
    case CARD_BRAND_VISA:
      return <VisaIcon {...props} />;
    case CARD_BRAND_AMEX:
      return <AmericanExpressIcon {...props} />
    case CARD_BRAND_MASTERCARD:
      return <MasterCardIcon {...props} />
    case CARD_BRAND_DISCOVER:
      return <DiscoverIcon {...props} />
    case CARD_BRAND_DINERS:
      return <DinersIcon {...props} />
    case CARD_BRAND_JCB:
      return <JSBIcon {...props} />;
    case CARD_BRAND_CHINA_UNION_PAY:
      return <CUPIcon {...props} />
    default:
      return <DefaultCardIcon {...props} />
  }
}

export default CreditCardBrandIcon;
