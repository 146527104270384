import { FC } from 'react';

const WhatsappGoldDarkIcon: FC = () => {
  return (
    <svg
      role="img"
      aria-labelledby="whatsapp-icon-title"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="whatsapp-icon-title">WhatsApp</title>
      <path
        d="M18.7946 3.19705C16.7263 1.1366 13.9761 0.00125713 11.046 0C5.00807 0 0.0943826 4.89019 0.0919653 10.9009C0.0912618 12.8223 0.595538 14.6978 1.55403 16.351L0 22L5.80687 20.4842C7.40678 21.3528 9.20817 21.8105 11.0413 21.8113H11.046H11.046C17.083 21.8113 21.9974 16.9202 22 10.9098C22.001 7.99667 20.8626 5.25764 18.7946 3.19705ZM11.046 19.97H11.0425C9.40849 19.9692 7.80615 19.5325 6.40853 18.7068L6.07592 18.5105L2.62999 19.41L3.55001 16.0665L3.3333 15.7237C2.42185 14.2809 1.94069 12.6136 1.94137 10.9016C1.94338 5.90569 6.02744 1.84128 11.0495 1.84128C13.4814 1.84228 15.7671 2.78599 17.4862 4.49857C19.2052 6.21114 20.1511 8.48776 20.1504 10.909C20.1483 15.9051 16.0641 19.97 11.046 19.97Z"
        fill="#E0E0E0"
      />
      <path
        d="M0.502808 21.373L1.98585 15.9821C1.07091 14.4044 0.589758 12.6144 0.590335 10.7811C0.592734 5.04509 5.28201 0.378418 11.0437 0.378418C13.8401 0.379675 16.4647 1.4631 18.438 3.42948C20.4118 5.39584 21.4981 8.00957 21.497 10.7893C21.4946 16.5255 16.805 21.1926 11.0441 21.1926C11.0437 21.1926 11.0444 21.1926 11.0441 21.1926H11.0394C9.29 21.192 7.57104 20.7548 6.04432 19.9264L0.502808 21.373Z"
        fill="#BAA57E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33941 6.27298C8.13676 5.82442 7.92338 5.81544 7.73058 5.8076C7.57288 5.80086 7.39234 5.80127 7.21209 5.80127C7.03169 5.80127 6.73855 5.86876 6.49065 6.13829C6.24261 6.40782 5.54358 7.05939 5.54358 8.38456C5.54358 9.70987 6.5132 10.9904 6.64835 11.1703C6.78365 11.3499 8.52009 14.1562 11.2701 15.2359C13.5556 16.1331 14.0207 15.9547 14.5168 15.9098C15.0129 15.8649 16.1175 15.2583 16.343 14.6293C16.5685 14.0005 16.5685 13.4615 16.5009 13.3488C16.4332 13.2365 16.2528 13.1692 15.9822 13.0345C15.7116 12.8998 14.3815 12.2481 14.1335 12.1583C13.8854 12.0685 13.705 12.0236 13.5246 12.2933C13.3442 12.5627 12.826 13.1692 12.6682 13.3488C12.5103 13.5288 12.3525 13.5512 12.0819 13.4165C11.8113 13.2814 10.9399 12.9973 9.90618 12.0797C9.10187 11.3658 8.55884 10.4842 8.401 10.2145C8.24315 9.94513 8.38408 9.79922 8.51981 9.66494C8.64128 9.54427 8.7904 9.35051 8.92569 9.19323C9.0607 9.03595 9.1058 8.9237 9.196 8.74411C9.28619 8.56424 9.2411 8.40697 9.17345 8.27228C9.1058 8.13759 8.57999 6.80557 8.33941 6.27298Z"
        fill="white"
      />
      <path
        d="M18.705 3.16232C16.6605 1.12552 13.9419 0.00322821 11.0455 0.00195312C5.0768 0.00195312 0.219521 4.836 0.217122 10.7777C0.216418 12.6771 0.714903 14.531 1.66239 16.1653L0.126221 21.7494L5.86644 20.2509C7.448 21.1096 9.22869 21.5621 11.0408 21.5628H11.0454H11.0455C17.0131 21.5628 21.8711 16.7279 21.8737 10.7864C21.8747 7.90684 20.7493 5.19926 18.705 3.16232ZM11.0455 19.7428H11.0419C9.42671 19.7419 7.84275 19.3102 6.46118 18.4941L6.13238 18.3L2.72602 19.1891L3.63546 15.884L3.42124 15.5452C2.52026 14.119 2.04461 12.4708 2.04532 10.7785C2.04728 5.83989 6.08446 1.82213 11.0489 1.82213C13.4529 1.82312 15.7124 2.75599 17.4117 4.44891C19.111 6.14183 20.0461 8.3923 20.0453 10.7858C20.0432 15.7245 16.0059 19.7428 11.0455 19.7428Z"
        fill="white"
      />
    </svg>
  );
};

export default WhatsappGoldDarkIcon;
