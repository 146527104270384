import { FC } from 'react';

const CheckYourEmailIcon: FC = () => {
  return (
    <svg width="80" height="56" viewBox="0 0 80 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="12.4912" width="72.2807" height="42.1053" fill="white"/>
      <path d="M72.9824 12.8649V12.5064C72.9824 12.1479 72.6175 11.7895 72.2526 11.7895H0.729825C0.364913 11.7895 0 12.1479 0 12.5064V12.8649C0 12.9843 0 13.2233 0 13.3428V55.2831C0 55.6415 0.364913 56 0.729825 56H72.131C72.4959 56 72.8608 55.6415 72.8608 55.2831V13.3428C72.9825 13.2233 72.9824 12.9843 72.9824 12.8649ZM70.9146 13.2233L36.4912 39.1522L2.06784 13.2233H70.9146ZM71.4012 54.5661H1.58129V14.6572L36.1263 40.7055C36.248 40.825 36.3696 40.825 36.6129 40.825C36.8561 40.825 36.9778 40.825 37.0994 40.7055L71.6444 14.6572V54.5661H71.4012Z" fill="#B2B2B2"/>
      <circle cx="71.5789" cy="9.68421" r="8.42105" fill="#191919"/>
      <path d="M71.5634 6.50041L69.6034 8.11041L68.6234 6.94841L71.7174 4.45641H73.2434V14.3684H71.5634V6.50041Z" fill="white"/>
    </svg>
  );
};

export default CheckYourEmailIcon;
