import { FC } from 'react';

const CloseIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g>
        <path
          d="M13.6464 0.646423L0.646423 13.6464L0.999977 14L1.35353 14.3536L14.3536 1.35353L14 0.999977L13.6464 0.646423Z"
          fill="#191919"
          stroke="#505050"
        />
        <path
          d="M1.5848 0.877258L14.3575 13.65L14.0038 14.0037L13.6501 14.3573L0.877502 1.58456L1.23115 1.23091L1.5848 0.877258Z"
          fill="#191919"
          stroke="#505050"
        />
      </g>
    </svg>
  );
};

export default CloseIcon;
