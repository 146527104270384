import { FC } from 'react';

const ErrorIcon: FC = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 0C6.74556 0 0 6.70118 0 15C0 23.2988 6.74556 29.9556 15 29.9556C23.2544 29.9556 30 23.2544 30 15C30 6.74556 23.2544 0 15 0ZM15 28.4024C7.58876 28.4024 1.59763 22.3669 1.59763 15C1.59763 7.63313 7.58876 1.55325 15 1.55325C22.4112 1.55325 28.4467 7.58876 28.4467 15C28.4467 22.4112 22.4112 28.4024 15 28.4024Z" fill="#B71610"/>
      <path d="M15.8877 6.87866H14.1125V19.3047H15.8877V6.87866Z" fill="#B71610"/>
      <path d="M15.8877 20.8582H14.1125V23.0771H15.8877V20.8582Z" fill="#B71610"/>
    </svg>
  );
};

export default ErrorIcon;
