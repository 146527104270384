import { FC } from 'react';

const CheckIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.6851 25.9723L1.21741 16.1429L4.00269 13.2512L10.6851 20.189L27.215 3.02773L30 5.91939L10.6851 25.9723Z"
        fill="#BAA57E"
      />
    </svg>
  );
};

export default CheckIcon;
