import { FC } from 'react';

const EmailIcon: FC = () => {
  return (
    <svg
      role="img"
      aria-labelledby="email-icon-title"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="email-icon-title">Email</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.61905 4.05882C2.75596 4.05882 2.04762 4.77474 2.04762 5.64706V18.3529C2.04762 19.2253 2.75596 19.9412 3.61905 19.9412H20.381C21.244 19.9412 21.9524 19.2253 21.9524 18.3529V5.64706C21.9524 4.77474 21.244 4.05882 20.381 4.05882H3.61905ZM1 5.64706C1 4.18997 2.17737 3 3.61905 3H20.381C21.8226 3 23 4.18997 23 5.64706V18.3529C23 19.81 21.8226 21 20.381 21H3.61905C2.17737 21 1 19.81 1 18.3529V5.64706Z"
        fill="#BAA57E"
        stroke="#BAA57E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.09473 5.23997C1.26063 4.98547 1.58724 4.92358 1.82424 5.10173L12 12.7509L22.1758 5.10173C22.4128 4.92358 22.7394 4.98547 22.9053 5.23997C23.0712 5.49448 23.0135 5.84521 22.7765 6.02336L12.3004 13.8983C12.12 14.0339 11.88 14.0339 11.6996 13.8983L1.22347 6.02336C0.986472 5.84521 0.928835 5.49448 1.09473 5.23997Z"
        fill="#BAA57E"
        stroke="#BAA57E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmailIcon;
