import { FC } from 'react';

const CheckeboxIconChecked: FC = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="29" height="29" rx="4.5" fill="white" stroke="#B2B2B2"/>
      <g clipPath="url(#clip0_2830_9431)">
        <g clipPath="url(#clip1_2830_9431)">
          <path d="M12.9312 20.7387L7.78261 15.3934L9.29727 13.8209L12.9312 17.5937L21.9203 8.26129L23.4348 9.8338L12.9312 20.7387Z" fill="#BAA57E"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2830_9431">
          <rect width="18" height="13" fill="white" transform="translate(7 8)"/>
        </clipPath>
        <clipPath id="clip1_2830_9431">
          <rect width="15.6522" height="16.25" fill="white" transform="translate(7.78261 6.375)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckeboxIconChecked;
