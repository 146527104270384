import { FC } from 'react';

const CalendarIcon: FC = () => {
  return (
    <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.68293 1C7.67584 1 6.85366 1.81465 6.85366 2.8125V4.625H1.36585C1.12353 4.64131 1.00462 4.83416 1 4.9875V29.6375C1.00002 29.8273 1.1743 30 1.36585 30H30.6341C30.8257 30 31 29.8273 31 29.6375V4.9875C31 4.79773 30.8257 4.62503 30.6341 4.625H25.1463V2.8125C25.1463 1.81465 24.3242 1 23.3171 1C22.31 1 21.4878 1.81465 21.4878 2.8125V4.625H10.5122V2.8125C10.5122 1.81465 9.69001 1 8.68293 1ZM8.68293 1.725C9.29731 1.725 9.78049 2.20375 9.78049 2.8125V7.1625C9.78049 7.77124 9.29731 8.24999 8.68293 8.24999C8.06855 8.24999 7.58537 7.77124 7.58537 7.1625V2.8125C7.58537 2.20375 8.06855 1.725 8.68293 1.725ZM23.3171 1.725C23.9315 1.725 24.4146 2.20375 24.4146 2.8125V7.1625C24.4146 7.77124 23.9315 8.24999 23.3171 8.24999C22.7027 8.24999 22.2195 7.77124 22.2195 7.1625V2.8125C22.2195 2.20375 22.7027 1.725 23.3171 1.725ZM1.73171 5.35H6.85366V7.1625C6.85366 8.16035 7.67584 8.97499 8.68293 8.97499C9.69001 8.97499 10.5122 8.16035 10.5122 7.1625V5.35H21.4878V7.1625C21.4878 8.16035 22.31 8.97499 23.3171 8.97499C24.3242 8.97499 25.1463 8.16035 25.1463 7.1625V5.35H30.2683V11.15H1.73171V5.35ZM1.73171 11.875H30.2683V29.275H1.73171V11.875Z" fill="#BAA57E" stroke="#BAA57E" strokeWidth="0.5"/>
    </svg>
  );
};

export default CalendarIcon;
